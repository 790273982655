import { Result } from "antd";
import React from "react";

export const UnauthorizedPage: React.FC = () => {
    return (
        <div className="unauthorized-page">
            <Result 
                status="403" 
                title="Unauthorized..." 
                subTitle="You are not authorized to access this page."
            />
        </div>
    );
}