export const permitted = [
    {    
        email: 'anthonylo@lifung.com',
        divisions: ['all', 'lfxvp', 'a8', 'u3', 'corp', 'sdu'],
    },
    {    
        email: 'edlam@lfxdigital.com',
        divisions: ['all', 'lfxvp', 'a8', 'u3', 'corp', 'sdu'],
    },
    {    
        email: 'edlam@lifung.com',
        divisions: ['all', 'lfxvp', 'a8', 'u3', 'corp', 'sdu'],
    },    
    {
        email: 'erikkwok@air8.io',
        divisions: ['a8']
    },
    {
        email: 'glennwong@air8.io',
        divisions: ['a8']
    },
    {
        email: 'janie@lfxvp.com',
        divisions: ['lfxvp']
    },
    {
        email: 'jeremytoh@lifung.com',
        divisions: ['corp']
    },         
    {
        email: 'jessicalee@unifi3d.co',
        divisions: ['u3']
    },    
    {
        email: 'john@lfxvp.com',
        divisions: ['lfxvp']
    },     
    {    
        email: 'lincolnyeh@lfxdigital.com',
        divisions: ['all', 'lfxvp', 'a8', 'u3', 'corp', 'sdu'],
    },   
    {    
        email: 'markleungcl@lfxdigital.com',
        divisions: ['all', 'lfxvp', 'a8', 'u3', 'corp', 'sdu'],
    },     
    {
        email: 'paulwong@lifung.com',
        divisions: ['corp']
    },             
    {
        email: 'wennyhuang@UNIFi3D.co',
        divisions: ['u3']
    },     
];