import { useNavigate } from "react-router-dom";
import { CompanyBox } from "./company.box";
import './industry.grid.css';
import { Checkbox } from "antd";
import { useState } from "react";

export interface IndustryGridProps {
    companies: any[];
    extendIcon?: boolean;
    extension?: boolean;
    group?: string;
    mode: 'numeric' | 'pictorial';
    mile: number;
    name: string;
    onSelectCompare?: (item: any) => boolean,
    override?: boolean;
    selectable?: boolean;
    type: number;
}

export const IndustryGrid: React.FC<IndustryGridProps> = ({
    companies,
    extendIcon,
    extension,
    group,
    mile,
    mode,
    name,
    onSelectCompare,
    override,
    selectable,
    type,
}) => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState<boolean>(false);

    const onNavigateClick = () => {
        console.log(`<IndustryGrid> onNavigateClick(): group=${group}, name=${name}`);
        navigate(`/categories/${name}${ group ? '?g=' + group : ''}`);
    }

    const onCheckboxClicked = () => {
        console.log(`<IndustryGrid> onCheckboxClicked(): Entered`)
        if (onSelectCompare) {
            console.log(`<IndustryGrid> onCheckboxClicked(): Running check`)
            const result = onSelectCompare(name);
            console.log(`<IndustryGrid> onCheckboxClicked(): result=${JSON.stringify(result)}`);
            setSelected(result);
        }        
    }

    return (
        <div className={`industry-grid industry-grid-${type}${override ? '-override' : ''}${extension ? 'industry-grid-xl' : ''}`}>
            <div className="industry-grid-companies">
                <div className={`summary-box industry-grid-companies-${mile}`}>
                    <div className="summary-box-title" onClick={onNavigateClick}>
                        {name}
                        <span>({companies.length})</span>
                    </div>
                    <div className="summary-box-companies-container">
                        <div className="summary-box-companies-top" onClick={onNavigateClick}>
                            <div className="summary-box-companies">
                                {
                                    companies.slice(0, mile === 4 || mile === 0? 50 : (extension ? 16 : (extendIcon ? 10: 10))).map((company, index) => (
                                        <CompanyBox 
                                            id={company.id}
                                            description={company.description}
                                            founded={company.founded}
                                            hq={company.hq}
                                            performance={company.performance}
                                            round={company.round}
                                            raised={company.raised}
                                            logo={company.icon} 
                                            name={company.name} 
                                            key={`company-${index}`}
                                            url={company.url}
                                        />                        
                                    ))
                                }
                            </div>
                        </div>
                        {
                            selectable ?
                                (
                                    <div className="selection">
                                        <Checkbox onChange={onCheckboxClicked} checked={selected} />
                                    </div>
                                ) : <></>
                        }                        
                    </div>
                </div>
            </div>
        </div>
    )
}