import { useNavigate } from "react-router-dom";
import './industry-num.grid.css';
import { Checkbox } from "antd";
import { useCallback, useState } from "react";
import { IndustryGridProps } from "./industry.grid";

export const IndustryNumericGrid: React.FC<IndustryGridProps> = ({
    companies,
    group,
    mile,
    mode,
    name,
    onSelectCompare,
    override,
    selectable,
    type,
}) => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState<boolean>(false);

    const onNavigateClick = useCallback(() => {
        console.log(`<IndustryGrid> onNavigateClick(): group=${group}, name=${name}`);
        navigate(`/categories/${name}${ group ? '?g=' + group : ''}`);
    }, [name, group, navigate]);

    const onCheckboxClicked = () => {
        console.log(`<IndustryGrid> onCheckboxClicked(): Entered`)
        if (onSelectCompare) {
            console.log(`<IndustryGrid> onCheckboxClicked(): Running check`)
            const result = onSelectCompare(name);
            console.log(`<IndustryGrid> onCheckboxClicked(): result=${JSON.stringify(result)}`);
            setSelected(result);
        }        
    }

    return (
        <div className={`industry-grid industry-grid-num-${type}${override ? '-override' : ''}`}>
            <div className={`industry-grid-companies-num`}>
                <div className={`summary-box-num industry-grid-companies-num-${mile}`}>
                    <div className="summary-box-title" onClick={onNavigateClick}>
                        <div>{name}</div>
                    </div>
                    <div className="summary-box-value">
                        <div className="summary-box-num-value-number">
                            {
                                selectable ?
                                    (
                                        <div className="selection">
                                            <Checkbox onChange={onCheckboxClicked} checked={selected} />
                                        </div>
                                    ) : <></>
                                    
                            }                              
                            <div className="data-value">{companies.length}</div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}