import { useCallback, useEffect, useState } from "react";
import { IndustryGrid } from "./industry.grid";
import './landscape.canvas.css';
import { SourceLoader } from '../lib/loader';
import { Spin } from "antd";
import { IndustryNumericGrid } from "./industry-num.grid";
import { Masonry } from "@mui/lab";

export interface LandscapeCanvasProps {
    mode: 'numeric' | 'pictorial';
    selectable?: boolean;
    selectedGroup?: string;
    onSelectCompany?: (industry: string) => boolean;
    onCompanyAvailable: (count: number) => void;
}

export const LandscapeCanvas: React.FC<LandscapeCanvasProps> = ({
    mode,
    selectable,
    selectedGroup,
    onSelectCompany,
    onCompanyAvailable,
}) => {    
    const [sectors, setSectors] = useState<any[]>([]);
    const industries = useCallback(async () => {
        return SourceLoader();
    }, []);
    
    useEffect(() => {
        console.log(`<LandscapeCanvas> industries=${JSON.stringify(industries.length)}`);                
        industries().then((v: any[] | undefined) => {    
            if (v) {
                console.log(`<LandscapeCanvas> selectedGroup=${JSON.stringify(selectedGroup)}`);                
                const selected = selectedGroup && selectedGroup !== 'all' ? 
                    v.map((industry) => {
                        industry.companies = industry.companies.filter((c: any) => c.group === selectedGroup);
                        return industry;
                    }) :
                    v;
                onCompanyAvailable(selected.reduce((acc, cur) => acc + cur.companies.length, 0));
                setSectors(selected.sort((a,b) => a.type < b.type ? 1 : -1))
            } 
        });
    }, [industries, selectedGroup]);

    return sectors.length > 0 ?
        (
            <div className="landscape-canvas">
                <div className="canvas-headers">
                    <div className={`canvas-header canvas-header ${mode === 'numeric' ? 'canvas-header-num': 'canvas-header-icon'} canvas-header-yellow`}>First Mile</div>
                    <div className={`canvas-header canvas-header-xs ${mode === 'numeric' ? 'canvas-header-num': 'canvas-header-icon'} canvas-header-red`}>Middle Mile</div>
                    <div className={`canvas-header canvas-header-blue ${mode === 'numeric' ? 'canvas-header-num': 'canvas-header-icon'}`}>Last Mile</div>
                </div>
                <div className="canvas-body">
                    {
                        mode === 'numeric' ?
                            <div className="canvas-mile">
                                <Masonry columns={2} spacing={1}>
                                {
                                    sectors.filter((s) => s.type === 1)
                                        .sort((a, b) => a.name < b.name ? -1 : 1)
                                        .map((sector, index) => {
                                            return (
                                                <IndustryNumericGrid
                                                    group={selectedGroup}
                                                    mile={1}
                                                    mode={mode}
                                                    key={`industry-grid-${index}`}
                                                    name={sector.name}
                                                    companies={sector.companies}
                                                    onSelectCompare={onSelectCompany}
                                                    override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                                    selectable={selectable}
                                                    type={sector.type}
                                                />
                                            );
                                        })
                                }
                                </Masonry>
                            </div> :
                            <div className="canvas-mile-icon">
                                <Masonry columns={1} spacing={0.5}>
                                    {
                                        sectors.filter((s) => s.type === 1)
                                            .sort((a, b) => a.name < b.name ? -1 : 1)
                                            .map((sector, index) => (
                                                <IndustryGrid
                                                    group={selectedGroup}
                                                    mile={1}
                                                    mode={mode}
                                                    key={`industry-grid-${index}`}
                                                    name={sector.name}
                                                    companies={sector.companies}
                                                    onSelectCompare={onSelectCompany}
                                                    override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                                    selectable={selectable}
                                                    type={sector.type}                                
                                                />                                    
                                            ))
                                    }
                                </Masonry>                                
                            </div>
                    }    
                    {
                        mode === 'numeric' ?
                            <div className="canvas-mile">
                                <Masonry columns={2} spacing={1}>
                                {
                                    sectors.filter((s) => s.type === 2)
                                        .sort((a, b) => a.name < b.name ? -1 : 1)
                                        .map((sector, index) => (
                                        <IndustryNumericGrid
                                            group={selectedGroup}
                                            mile={2}
                                            mode={mode}
                                            key={`industry-grid-${index}`}
                                            name={sector.name}
                                            companies={sector.companies}
                                            onSelectCompare={onSelectCompany}
                                            override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                            selectable={selectable}
                                            type={sector.type}                                
                                        />
                                    ))
                                }
                                </Masonry>
                            </div> :
                            <div className="canvas-mile-icon canvas-mile-icon-xs">                                
                                <Masonry columns={2} spacing={0.5}>
                                    {
                                        sectors.filter((s) => s.type === 2)
                                            .sort((a, b) => a.name < b.name ? -1 : 1)
                                            .map((sector, index) => (
                                            <IndustryGrid
                                                extension={true}
                                                group={selectedGroup}
                                                mile={3}
                                                mode={mode}
                                                key={`industry-grid-${index}`}
                                                name={sector.name}
                                                companies={sector.companies}
                                                onSelectCompare={onSelectCompany}
                                                override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                                selectable={selectable}
                                                type={sector.type}                                
                                            />                                    
                                        ))
                                    }
                                </Masonry>
                            </div>
                    }              
                    {
                        mode === 'numeric' ?
                            <div className="canvas-mile">
                                <Masonry columns={2} spacing={1}>
                                {
                                    sectors.filter((s) => s.type === 3)
                                        .sort((a, b) => a.name < b.name ? -1 : 1)
                                        .map((sector, index) => (
                                            <IndustryNumericGrid
                                                group={selectedGroup}
                                                mile={3}
                                                mode={mode}
                                                key={`industry-grid-${index}`}
                                                name={sector.name}
                                                companies={sector.companies}
                                                onSelectCompare={onSelectCompany}
                                                override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                                selectable={selectable}
                                                type={sector.type}                                
                                            />
                                        ))
                                }
                                </Masonry>
                            </div> :
                            <div className="canvas-mile-icon">
                                <Masonry columns={2} spacing={0.5}>
                                    {
                                        sectors.filter((s) => s.type === 3)
                                            .sort((a, b) => a.name < b.name ? -1 : 1)
                                            .map((sector, index) => (
                                                <IndustryGrid
                                                    extendIcon={true}
                                                    group={selectedGroup}
                                                    mile={3}
                                                    mode={mode}
                                                    key={`industry-grid-${index}`}
                                                    name={sector.name}
                                                    companies={sector.companies}
                                                    onSelectCompare={onSelectCompany}
                                                    override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                                    selectable={selectable}
                                                    type={sector.type}                                
                                                />                                    
                                            ))
                                    }
                                </Masonry>
                            </div>
                    }     
                    <div className={`canvas-full-mile ${mode ==='pictorial' ? "mile-icon" : ""}`}>
                        <>
                            {
                                sectors.filter((s) => s.type === 0)
                                    .sort((a, b) => a.name < b.name ? -1 : 1)
                                    .map((sector, index) => {
                                        if (mode === 'numeric') {
                                            return (
                                                <IndustryNumericGrid
                                                    group={selectedGroup}
                                                    mile={4}
                                                    mode={mode}
                                                    key={`industry-grid-${index}`}
                                                    name={sector.name}
                                                    companies={sector.companies}
                                                    onSelectCompare={onSelectCompany}
                                                    override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                                    selectable={selectable}
                                                    type={sector.type}                                
                                                />
                                            );
                                            
                                        } else {
                                            return (
                                                <IndustryGrid
                                                    group={selectedGroup}
                                                    mile={4}
                                                    mode={mode}
                                                    key={`industry-grid-${index}`}
                                                    name={sector.name}
                                                    companies={sector.companies}
                                                    onSelectCompare={onSelectCompany}
                                                    override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                                    selectable={selectable}
                                                    type={sector.type}                                
                                                />                                    
                                            )
                                        }
                                    })
                            }
                        </>
                    </div>
                    <div className={`canvas-full-mile ${mode ==='pictorial' ? "mile-icon" : ""}`}>
                        <>
                            {
                                sectors.filter((s) => s.type === 4)
                                    .sort((a, b) => a.name < b.name ? -1 : 1)
                                    .map((sector, index) => {
                                        if (mode === 'numeric') {
                                            return (
                                                <IndustryNumericGrid
                                                    group={selectedGroup}
                                                    mile={4}
                                                    mode={mode}
                                                    key={`industry-grid-${index}`}
                                                    name={sector.name}
                                                    companies={sector.companies}
                                                    onSelectCompare={onSelectCompany}
                                                    override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                                    selectable={selectable}
                                                    type={sector.type}                                
                                                />
                                            );
                                            
                                        } else {
                                            return (
                                                <IndustryGrid
                                                    group={selectedGroup}
                                                    mile={4}
                                                    mode={mode}
                                                    key={`industry-grid-${index}`}
                                                    name={sector.name}
                                                    companies={sector.companies}
                                                    onSelectCompare={onSelectCompany}
                                                    override={sectors.filter((s) => s.type === 1).length === 1 ? true : false}
                                                    selectable={selectable}
                                                    type={sector.type}                                
                                                />                                    
                                            )
                                        }
                                    })
                            }
                        </>
                    </div>                
                    
                </div>
            </div>            
        ) :
        (
            <div className="landscape-loading">
                <Spin tip="No Data Available" size="large" style={{ marginTop: '40vh'}}>
                    <div className="content" />
                </Spin>
                <div className="landscape-loading-caption"></div>
            </div>
        );
}