import { BrowserRouter} from "react-router-dom";
import { ConfigProvider } from "antd";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { ApplicationRouter } from "./router/app.router";
import { ApplicationTheme } from "./theme/app.theme";
import { LoginPage } from "./pages/login.page";


export const App = ({
  msalInstance,
}: {
  msalInstance: PublicClientApplication;
}) => {  
  return (
      <MsalProvider instance={msalInstance}>    
        <ConfigProvider theme={ApplicationTheme}>
          <AuthenticatedTemplate>
            <BrowserRouter>
              <ApplicationRouter msal={msalInstance} />
            </BrowserRouter>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <LoginPage />
          </UnauthenticatedTemplate>
        </ConfigProvider>        
      </MsalProvider>
  );
};
