import { Configuration, LogLevel } from "@azure/msal-browser";

// TODO: Update configs to use our endpoints and IDs

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_SIGNUP_SIGNIN,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN as string,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "https://www.lfxdigital.com",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Warning:
            console.warn(message);
            return;
          case LogLevel.Error:
            console.error(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [],
};
