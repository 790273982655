import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './comparison.page.css';
import { Table, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SourceLoader } from '../lib/loader';
import { LfxPageHeader } from '../components/page.header';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { permitted } from "../lib/permitted.config";
import { jwtDecode } from "jwt-decode";

export interface ComparisonPageProps {
    msal: any;
}

export const ComparisonPage: React.FC<ComparisonPageProps> = ({
    msal,
}) => {
    const parameters = useParams();
    const [industryA, setIndustryA] = useState<any[]>();
    const [industryB, setIndustryB] = useState<any[]>();
    const [selectedGroup, setSelectedGroup] = useState<string | undefined>();
    const location = useLocation();

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const {instance, accounts} = useMsal();
    const [userCred, setUserCred] = useState<{ email: string, divisions: string[]}>();    

    const sourceData = useMemo(() => {
        return SourceLoader();
    }, []);  

    const getLogoUrl = (url: string) => {
        const item = url + '.ico';
        const path = process.env.REACT_APP_ICON_PATH;
        const token = process.env.REACT_APP_SAS_TOKEN;
        return `${path}${item}${token}`;
    }

    const omRowClick = (event: any) => {
        console.log(`<ComparisonPage> onRowClick(): event=${JSON.stringify(event)}`);
        window.open(`https://${event.id}`, '_blank');
    }    
    
    const onUserSelectGroup = useCallback((group: any) => {
        console.log(`<ComparisonPage> onUserSelectGroup(): group=${JSON.stringify(group)}`);
        setSelectedGroup(group);
    }, [setSelectedGroup]);   
    
    useEffect(() => {        
        const code = new URLSearchParams(location.search).get('g');
        console.log(`<ComparisonPage> useEffect(): code=${JSON.stringify(code)}`);
        setSelectedGroup(code ?? undefined);
    }, [setSelectedGroup, location]);    

    useEffect(() => {
        sourceData.then((result: any[] | undefined) => {
            if (result) {
                const sourceDataA = result.find((v: any) => v.name === parameters.industryA);
                const targetA = sourceDataA.companies;
                const sourceDataB = result.find((v: any) => v.name === parameters.industryB);
                const targetB = sourceDataB.companies;

                setIndustryA(targetA.filter((i: any) => selectedGroup && selectedGroup !== 'all' ? i.group === selectedGroup : true));
                setIndustryB(targetB.filter((i: any) => selectedGroup && selectedGroup !== 'all' ? i.group === selectedGroup : true));
            }
        });
    }, [parameters.industryA, parameters.industryB, sourceData, selectedGroup]);

    useEffect(() => {
        console.log(`<LandingPage> useEffect(): isAuthenticated=${isAuthenticated}`);
        if (isAuthenticated) {
            instance.acquireTokenSilent({
                scopes: [],
                account: accounts[0],
            }).then((tokenResult) => {
                const token = (tokenResult.idTokenClaims as any).idp_access_token;
                console.log(`<LandingPage> useEffect(): token=${token}`);
                const jwt: any = jwtDecode(token);
                console.log(`<LandingPage> useEffect(): jwt=${JSON.stringify(jwt)}`);
                const target: string = jwt.unique_name;
                console.log(`<LandingPage> useEffect(): target=${target}`);
                const credentials = permitted.find((p) => p.email === target.toLowerCase());
                if (!credentials) {
                    navigate('/unauthorized');
                } else {
                    setUserCred(credentials);
                    if (!selectedGroup && !credentials.divisions.some((d) => d === 'all')) {
                        setSelectedGroup(credentials.divisions[0]);
                    } else {
                        setSelectedGroup('all');
                    }
                }
            }).catch((error) => {
                console.error(`<LandingPage> useEffect(): error=${JSON.stringify(error)}`);
            });
        }
    }, [isAuthenticated, instance, accounts, navigate, setSelectedGroup, setUserCred, selectedGroup]);


    const keys = [
        {
            dataIndex: 'logo',
            key: 'logo',
            title: '',
            width: 50,
            render: (text: string, record: any, index: number) => {
                return <img src={getLogoUrl(record.id)} alt={record.name} className="company-logo-large" style={{backgroundColor: '#fff'}} />
            },
        },      
        {
            dataIndex: 'name',
            key: 'name',
            title: 'Name',            
        },
        {
            dataIndex: 'description',
            key: 'description',
            title: 'Description',
        },
        {
            dataIndex: 'region',
            key: 'region',
            title: 'Region',
        },        
        {
            dataIndex: 'subcategory',
            key: 'subcategory',
            title: 'Sub Category',
        },
    ]    

    return (
        <div className="comparison-page">
            <LfxPageHeader isShowToggle={false} msal={msal} selectedGroup={selectedGroup} onGroupSelect={onUserSelectGroup} totalCompanies={(industryA?.length ?? 0) + (industryB?.length ?? 0)} credentials={userCred} />
            <div className="main-category-bar">
                <Typography.Title level={4}>Category Comparisons</Typography.Title>
            </div>          
            <div className="main-area">
                <div className="compare-base-category">
                    <Typography.Title level={5}>CATEGORY A: {parameters.industryA}</Typography.Title>
                    <Table dataSource={industryA} columns={keys} onRow={(rec, index) => {
                            return {
                                onClick: (event) => {omRowClick(rec)}
                            }
                    }}/>
                </div>
                <div className="compare-base-category">
                    <Typography.Title level={5}>CATEGORY B: {parameters.industryB}</Typography.Title>
                    <Table dataSource={industryB} columns={keys} onRow={(rec, index) => {
                            return {
                                onClick: (event) => {omRowClick(rec)}
                            }
                    }}/>
                </div>
            </div>  
        </div>
    )
}