export const ApplicationTheme = {
    components: {
        Button: {
            colorBgContainer: '#B0B3B8',
            colorLinkHover: '#fff',
            colorPrimaryHover: '#fff',
            fontWeight: 600,
            textHoverBg: '#3D3D3D',
            
        },
        Checkbox: {            
            colorBgContainer: '#000',
            colorBorder: '#B0B3B8',
            colorPrimary: '#B0B3B8',
            colorPrimaryHover: '#B0B3B8',
            colorIcon: '#000',
            colorWhite: '#000',
        },
        Pagination: {
            colorPrimary: '#000',
            colorPrimaryHover: '#3D3D3D',
            colorText: '#fff',
            itemActiveBg: '#B0B3B8',
            itemLinkBg: '#fff',
            itemSize: 24,
        },
        Result: {
            colorTextHeading: '#B0B3B8',
            colorTextDescription: '#fff',            
        },
        Table: {
            headerBg: '#B0B3B8',
            borderColor: '#B0B3B8',
            cellFontSize: 12,
            colorBgContainer: '#000',
            colorPrimary: '#B0B3B8',            
            colorText: '#fff',
            footerColor: '#fff',
            rowHoverBg: '#3D3D3D96',
            rowExpandedBg: '#555555',
        },
        Typography: {
            colorTextHeading: '#B0B3B8',
        }
    },
    token: {
          
    }
}