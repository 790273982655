import { Table, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SourceLoader } from "../lib/loader";
import './detail.page.css';
import { LfxPageHeader } from "../components/page.header";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { permitted } from "../lib/permitted.config";
import { jwtDecode } from "jwt-decode";

export interface CategoryPageProps {
    msal: any;
}

export const CategoryPage: React.FC<CategoryPageProps> = ({
    msal,
}) => {
    const { category } = useParams();
    const sourceData = useMemo(() => {
        return SourceLoader();
    }, []);  
    const [companies, setCompanies] = useState<any[]>();  
    const [selectedGroup, setSelectedGroup] = useState<string | undefined>();    
    const location = useLocation();

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const {instance, accounts} = useMsal();
    const [userCred, setUserCred] = useState<{ email: string, divisions: string[]}>();
    
    const grouping = process.env.REACT_APP_ACTIVATE_SUBCAT;  

    const getLogoUrl = (url: string) => {
        const item = url + '.ico';
        const path = process.env.REACT_APP_ICON_PATH;
        const token = process.env.REACT_APP_SAS_TOKEN;
        return `${path}${item}${token}`;
    }

    const omRowClick = (event: any) => {
        console.log(`<DetailPage> onRowClick(): event=${JSON.stringify(event)}`);
        window.open(`https://${event.id}`, '_blank');
    }

    const onUserSelectGroup = useCallback((group: any) => {
        console.log(`<LandingPage> onUserSelectGroup(): group=${JSON.stringify(group)}`);
        setSelectedGroup(group);
    }, [setSelectedGroup]);   
    
    useEffect(() => {        
        const code = new URLSearchParams(location.search).get('g');
        console.log(`<DetailPage> useEffect(): code=${JSON.stringify(code)}`);
        setSelectedGroup(code ?? undefined);
    }, [setSelectedGroup, location]);    
    
    useEffect(() => {        
        sourceData.then((result: any[] | undefined) => {
            if (result) {
                console.log(`<DetailPage> useEffect(): selectedGroup=${JSON.stringify(selectedGroup)}`)
                const datasource = result.find((v: any) => {
                    return v.name === category;                    
                });
                const targets = (datasource.companies as any[]).
                    filter((i) => selectedGroup && selectedGroup !== 'all' ?
                        i.group === selectedGroup :
                        true,
                    );
                console.log(`<DetailPage> targets: ${JSON.stringify(targets)}`);
                setCompanies(targets);
            }
        });
    }, [category, sourceData, setCompanies, selectedGroup]);

    useEffect(() => {
        console.log(`<LandingPage> useEffect(): isAuthenticated=${isAuthenticated}`);
        if (isAuthenticated) {
            instance.acquireTokenSilent({
                scopes: [],
                account: accounts[0],
            }).then((tokenResult) => {
                const token = (tokenResult.idTokenClaims as any).idp_access_token;
                console.log(`<LandingPage> useEffect(): token=${token}`);
                const jwt: any = jwtDecode(token);
                console.log(`<LandingPage> useEffect(): jwt=${JSON.stringify(jwt)}`);
                const target: string = jwt.unique_name;
                console.log(`<LandingPage> useEffect(): target=${target}`);
                const credentials = permitted.find((p) => p.email === target.toLowerCase());
                if (!credentials) {
                    navigate('/unauthorized');
                } else {
                    setUserCred(credentials);
                    if (!selectedGroup && !credentials.divisions.some((d) => d === 'all')) {
                        setSelectedGroup(credentials.divisions[0]);
                    } else {
                        setSelectedGroup('all');
                    }
                }
            }).catch((error) => {
                console.error(`<LandingPage> useEffect(): error=${JSON.stringify(error)}`);
            });
        }
    }, [isAuthenticated, instance, accounts, navigate, setSelectedGroup, setUserCred, selectedGroup]);


    const keys = [
        {
            dataIndex: 'logo',
            key: 'logo',
            title: '',
            width: 50,
            render: (text: string, record: any, index: number) => {
                return (
                    <img src={getLogoUrl(record.id)} alt={record.name} className="company-logo-large" style={{backgroundColor: '#fff'}} />
                )
            },
        },   
        {
            dataIndex: 'name',
            key: 'name',
            title: 'Name',            
        },
        {
            dataIndex: 'description',
            key: 'description',
            title: 'Description',
        },
        {
            dataIndex: 'region',
            key: 'region',
            title: 'Region',
        },        
        {
            dataIndex: 'subcategory',
            key: 'subcategory',
            title: 'Sub Category',
        },
   
    ]

    return (
        <div className="detail-page-container">
            <div className="header-bar">
                <LfxPageHeader isShowToggle={false} msal={msal} onGroupSelect={onUserSelectGroup} selectedGroup={selectedGroup} totalCompanies={companies?.length} credentials={userCred}/>
                <div className="header-bar-user"></div>
            </div>
            <div className="main-category-bar">
                <Typography.Title level={4}>{category}</Typography.Title>
            </div>
            <div className="main-area-table">            
                {
                    grouping === 'true' ? 
                        <div className="sub-category-bar"></div> :                         
                        <Table dataSource={companies} columns={keys} onRow={(rec, index) => {
                            return {
                                onClick: (event) => {omRowClick(rec)}
                            }
                        }}/>
                }
            </div> 
        </div>
    );
};