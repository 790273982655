export abstract class IndustryClassifier {    
    static classify(industry: string) {
        switch (industry?.toLowerCase()) {
            // complete set
            case 'product data connectivity':
            case 'real-time supply chain & retail platforms':
            case 'supply chain financing':
                return 0;
            
            // bottom
            case 'others':
                return 4;

            // final mile
            case 'big & bulky delivery':
            case 'cross-channel ecommerce data unification':
            case 'delivery optimization':
            case 'fulfillment optimization':
            case 'headless ecommerce infrastructure':
            case 'product data attribution':
            case 're-commerce':
            case 'retail store automation':
            case 'retail store data capture':
            case 'return/refund financing':
            case 'reverse logistics':
                return 3;

            // middle mile
            case 'drayage optimization':
            case 'freight digitization':
            case 'freight management':
            case 'inventory visibility':
            case 'transportation optimization':
            case 'transportation visibility':
            case 'warehouse automation & robotics':
            case 'yard management':
                return 2;

            // first mile
            case 'consumer trend insights':
            case 'data driven design':    
            case 'digital product development':
            case 'digital sourcing':
            case 'merchandising planning & inventory optimization':
            case 'next-gen manufacturing':
            case 'next-gen product information management':
            case 'next-gen product lifecycle management':
            case 'procurement optimization':
            case 'product traceability':
            case 'supplier sustainability data':
            case 'supply chain resiliency':
                return 1;
        }
    }
}