import { useState } from 'react';
import './company.box.css';
import { Popover } from 'antd';
import { FundOutlined, GlobalOutlined, LineChartOutlined } from '@ant-design/icons';

export interface CompanyBoxProps {
    id?: string,
    description?: string;
    founded?: string;
    hq?: string;
    performance?: string;
    round?: string;
    raised?: string;
    logo: string;
    name: string,
    url?: string,
}

export const CompanyBox: React.FC<CompanyBoxProps> = ({
    id,
    description,
    founded,
    hq,
    performance,
    round,
    raised,
    logo,
    url,
    name,
}) => {
    const [hovered, setHovered] = useState<boolean>(false);

    const handleOpenChange = (open: boolean) => {
        setHovered(open);
    }

    const getLogoUrl = (url: string) => {
        const item = id + '.ico';
        const path = process.env.REACT_APP_ICON_PATH;
        const token = process.env.REACT_APP_SAS_TOKEN;
        return `${path}${item}${token}`;
    }

    const popoverContent = (
        <div className="content-box">
            <div className="description">{description}</div>
            {
                raised || round ?
                    <div className="url-box">
                        <div><FundOutlined /></div>
                        <div>
                            { raised ?? '' }
                            { round ? "(" + round + ")" : "" }
                        </div>
                    </div> :
                    <></>
            }
            {
                performance ?
                    <div className="url-box">
                        <div><LineChartOutlined /></div>
                        <div>{performance}</div>
                    </div>
                    :
                    <></>
            }
            
            <div className="url-box">
                <div><GlobalOutlined /></div>
                <div>
                    <a href={url} target="_blank" rel="noreferrer">Visit Website</a>
                </div>
            </div>
        </div>
    )

    return (
        <Popover
            content={popoverContent}
            onOpenChange={handleOpenChange}
            open={hovered}
            style={{
                maxWidth: '300px'
            }}
            title={`${name} ${founded ? "(Founded " + founded + ")" : ""}`}
            trigger="hover"            
        >
            <div className="company-box">
                <img className="company-logo" src={logo} alt={name} />
            </div>
        </Popover>
    )
}