import { Button, Result, Spin, Typography } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../lib/msal.config";
import { useEffect } from "react";
import './login.page.css';

export const LoginPage = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    instance.handleRedirectPromise()
      .then((result: any) => {
        console.log(`LoginPage.onLoginClicked(): result=${JSON.stringify(result)}`);
        console.log(`LoginPage.onLoginClicked(): isAuthenticated=${JSON.stringify(isAuthenticated)}`);
        if (!isAuthenticated) {
          instance.loginRedirect(loginRequest)
            .then((result) => {
              instance.acquireTokenRedirect(loginRequest).then((tokenResult) => {
                console.log(`LoginPage.onLoginClicked(): tokenResult=${JSON.stringify(tokenResult)}`);
              })              
            })
            .catch((error) => {
              console.error(`LoginPage.onLoginClicked(): error=${JSON.stringify(error)}`);
            });
        } else {
          return undefined;
        }
      })
      .catch((error) => {
        console.error(`LoginPage.onLoginClicked(): error=${JSON.stringify(error)}`);
      });
  }, [instance, isAuthenticated]);

  
  return (
    <div className="login-page">
      <div className="positioner">
        <div className="logo">
          <img src="https://lfxdigital.com/wp-content/uploads/2021/02/LFX_Logo_Final-01.png" alt="welcome to LFX"/>
        </div>
        <div className="slogan">
          <Typography.Title level={5} style={{color: '#fff'}}>Welcome to Gloal Value Chain Landscape</Typography.Title>
        </div>
        <div className="spinner">
          <Spin tip="Getting data ready..." size="large" style={{color: 'rgb(128,0,0)'}} />
          <div style={{color: '#fff'}}>Getting data ready...</div>
        </div>
        <div className="disclaimer">
          <Typography.Paragraph style={{color: '#fff'}}>
            By logging into the application, you agree to all the Terms and Conditions for using the LFX VC Landscaper,
            and any unauthorised access will be recorded and may lead to further prosecuting actions.
          </Typography.Paragraph>
        </div>
      </div>      
    </div>
  );
};
