import { LandscapeCanvas } from '../components/landscape.canvas';
import './landing.page.css';
import { useCallback, useEffect, useState } from 'react';
import { SegmentedValue } from 'antd/es/segmented';
import { useNavigate } from 'react-router-dom';
import useMessage from 'antd/es/message/useMessage';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { permitted } from '../lib/permitted.config';
import { LfxPageHeader } from '../components/page.header';

export interface LandingPageProps {
    msal: any;
}

export const LandingPage: React.FC<LandingPageProps> = ({
    msal,
}) => {
    const navigate = useNavigate();
    const {instance, accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [api] = useMessage();
    const [mode, setMode] = useState<'numeric' | 'pictorial'>('numeric');
    const [compareMode, setComppareMode] = useState<boolean>(false);
    const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
    const [compareError, setComppareError] = useState<string>();
    const [selectedGroup, setSelectedGroup] = useState<string>();
    const [availableCompanies, setAvailableCompanies] = useState<number>();
    const [userCred, setUserCred] = useState<{ email: string, divisions: string[] }>();

    const onModeSelect = (value: SegmentedValue) => {
        if (value !== 'comparison') {
            const settable = value as 'numeric' | 'pictorial';
            setMode(settable);
        } else {
            setComppareMode(!compareMode);
        }
    }

    const trigger = useCallback((value: string) => {
        console.log('<LandingPage> trigger(): Enter');
        setComppareError(value);
    }, [setComppareError]);

    const onCompareFire = () => {
        console.log('<LandingPage> onCompareFire(): Enter');
        console.log('<LandingPage> onCompareFire(): size: %s', selectedCompanies.length);
        const limit = process.env.REACT_APP_COMPARE_LIMIT ?? '2';

        if (selectedCompanies.length < Number.parseInt(limit)) {
           trigger(`The comparison feature requires ${limit} companies selected.`);
        } else if (selectedCompanies.length > Number.parseInt(limit)) {
            trigger(`The comparison feature only support up to ${limit} companies at this time`);
        } else {
            navigate(`/compare/${selectedCompanies.map((c) => encodeURIComponent(c)).join('/')}${selectedGroup ? `?g=${selectedGroup}` : ''}`);
        }
    }

    const onSelectCompany = (industry: string) => {
        if (!selectedCompanies.includes(industry)) {
            selectedCompanies.push(industry);
            setSelectedCompanies(selectedCompanies);
            return true;
        } else {
            return false;
        }
    }

    const filter = process.env.REACT_APP_ACTIVATE_FILTER;

    const onUserSelectGroup = useCallback((group: any) => {
        console.log(`<LandingPage> onUserSelectGroup(): group=${JSON.stringify(group)}`);
        setSelectedGroup(group);
    }, [setSelectedGroup]);

    useEffect(() => {
        if (compareError) {
            console.log(`<LandingPage> useEffect(): trigger API`)
            api.open({
                type: 'error',
                content: compareError,
            });
            setTimeout(() => setComppareError(undefined), 10000);
        }
    }, [compareError, setComppareError, api]);

    useEffect(() => {
        if (isAuthenticated) {
            instance.acquireTokenSilent({
                scopes: [],
                account: accounts[0],
            }).then((tokenResult) => {
                const token = (tokenResult.idTokenClaims as any).idp_access_token;
                const jwt: any = jwtDecode(token);
                const target: string = jwt.unique_name;
                const credentials = permitted.find((p) => p.email === target.toLowerCase());
                if (!credentials) {
                    navigate('/unauthorized');
                } else {
                    setUserCred(credentials);
                    if (!selectedGroup && !credentials.divisions.some((d) => d === 'all')) {
                        setSelectedGroup(credentials.divisions[0]);
                    } else if (!selectedGroup) {
                        setSelectedGroup('all');
                    }
                }
            }).catch((error) => {
                console.error(`<LandingPage> useEffect(): error=${JSON.stringify(error)}`);
            });
        }
    }, [isAuthenticated, instance, accounts, navigate, setSelectedGroup, setUserCred, selectedGroup]);

    return (
        <>
            <LfxPageHeader 
                isShowToggle={true} 
                totalCompanies={availableCompanies}
                onCompareFire={onCompareFire} 
                onVisualModeChange={setMode} 
                onCompareModeChange={setComppareMode} 
                onGroupSelect={onUserSelectGroup}
                msal={msal} 
                credentials={userCred}
            />
            {
                <LandscapeCanvas 
                    mode={mode} 
                    selectable={compareMode} 
                    onSelectCompany={onSelectCompany} 
                    onCompanyAvailable={setAvailableCompanies}
                    selectedGroup={selectedGroup} 
                />
            }
        </>
    );
}