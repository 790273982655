import { Route, Routes } from "react-router-dom";
import { LandingPage } from "../pages/landing.page";
import { CategoryPage } from "../pages/detail.page";
import { ComparisonPage } from "../pages/comparison.page";
import { UnauthorizedPage } from "../pages/unauthorized.page";

export interface ApplicationRouterProps {
    msal: any,
}

export const ApplicationRouter: React.FC<ApplicationRouterProps> = ({
    msal
}) => (
    <Routes>
        <Route path="/" element={<LandingPage msal={msal} />} />
        <Route path="/categories/:category" element={<CategoryPage  msal={msal} />} />
        <Route path="/compare/:industryA/:industryB" element={<ComparisonPage  msal={msal}  />} />
        <Route path='/unauthorized' element={<UnauthorizedPage />} />
    </Routes>
);
