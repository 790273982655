import { AppstoreOutlined, PercentageOutlined, PictureOutlined } from "@ant-design/icons";
import { Button, Segmented, Select, Upload, UploadProps, message } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { useState } from "react";
import './page.header.css';
import { on } from "events";

export interface LfxPageHeaderProps {
    isShowToggle: boolean;
    onCompareFire?: () => void;
    onVisualModeChange?: (mode: 'numeric' | 'pictorial') => void;
    onCompareModeChange?: (mode: boolean) => void;    
    onGroupSelect?: (group: any) => void;
    msal: any,
    selectedGroup?: string | null;
    totalCompanies?: number;
    credentials?: { email: string, divisions: string[] };
}

export const LfxPageHeader: React.FC<LfxPageHeaderProps> = ({
    isShowToggle,
    onCompareFire,
    onCompareModeChange,
    onVisualModeChange,
    onGroupSelect,
    msal,
    selectedGroup,    
    totalCompanies,
    credentials,
}) => {
    const [compareMode, setComppareMode] = useState<boolean>(false);
    const [activeGroup, setActiveGroup] = useState<string | null | undefined>(selectedGroup);

    const onLogoutClick = async () => {
        const account = msal.getAllAccounts();
        console.log(`account=${JSON.stringify(account)}`);
        const currentAccount = msal.getAccountByHomeId(account[0].homeAccountId);
        msal.logoutRedirect({
            account: currentAccount,
            onRedirectNavigate: (url: string) => {
                console.log(`admin: ${url}`);
                window.open(url, '_self');
            }
        });
        
    };

    const onModeSelect = (value: SegmentedValue) => {
        if (value !== 'comparison') {
            const settable = value as 'numeric' | 'pictorial';
            if (onVisualModeChange) onVisualModeChange(settable);
            if (onCompareModeChange) onCompareModeChange(false);            
            setComppareMode(false);
        } else {
            if (onCompareModeChange) onCompareModeChange(true);
            setComppareMode(!compareMode);   
        }
    }

    const onSourceGroupSelect = (value: any) => {
        setActiveGroup(value);
        if (onGroupSelect) onGroupSelect(value);
    }

    const uploadProps: UploadProps = {
        action: `https://stalfxtcseanpslsfile.blob.core.windows.net/upload/${credentials?.email.split('@')[0].toLowerCase()}_${new Date().getTime()}.xlsx${process.env.REACT_APP_SAS_TOKEN}`,

        maxCount: 1,
        
        method: 'PUT',

        name: `${credentials?.email.split('@')[0].toLowerCase()}_${new Date().getTime()}.xlsx`,

        headers: {
            "x-ms-write": 'update',
            "x-ms-version": '2018-11-09',
            "x-ms-blob-type": 'BlockBlob',
        },

        showUploadList: false,

        beforeUpload: (file) => {
            const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isXLSX) {
                message.error('You can only upload XLSX file!');
            } 
            return isXLSX || Upload.LIST_IGNORE;        
        },

        onChange: ({file}) => {
            if (file.status === 'done') {
                message.success(`Upload completed`);
            }
        },
    };

    return (
        <div className="page-header">
            <div className="page-header-logo">
                <a href={`/${activeGroup && activeGroup !== null ? '?g=' + activeGroup : ''}`}>
                    <img src="https://lfxdigital.com/wp-content/uploads/2021/02/LFX_Logo_Final-01.png" alt="LFX Logo" />
                </a>
            </div>
            <div className="page-header-title">
                <a href="/">Digital Value Chain Landscape</a>
                {
                    totalCompanies && totalCompanies > 0 ?
                        <span className="page-header-total-companies"> ({totalCompanies} Companies)</span> :
                        <></>
                }                
            </div>
            <div className="page-header-group">
                <Select
                    style={{ width: '150px' }}
                    onChange={onSourceGroupSelect}
                    value={selectedGroup}
                    options={(credentials?.divisions ?? [])
                        .map(
                            (d) => [
                                { value: 'all', label: 'All' },
                                { value: 'corp', label: 'Corporate Development' },
                                { value: 'lfxvp', label: 'LFX VP' },
                                { value: 'a8', label: 'Air8' },
                                { value: 'u3', label: 'UNIFi3D' },
                                { value: 'sdu', label: 'SDU' },
                            ]
                            .find((a) => a.value === d) ?? { value: 'na', label: 'N/A' }
                        )
                    }
                />
            </div>
            <div className="page-header-toggle">
                {
                    isShowToggle && (
                        <>
                            {
                                compareMode ?
                                    (
                                        <span style={{ marginRight: 10}}>
                                            <Button onClick={onCompareFire}>Compare</Button>
                                        </span>
                                    ) :
                                    <></>
                            }
                            <Segmented
                                onChange={onModeSelect}
                                options={[
                                {
                                    label: (
                                        <PercentageOutlined />
                                    ),
                                    value: 'numeric'
                                },
                                {
                                    label: (
                                        <PictureOutlined />
                                    ),
                                    value: 'pictorial'
                                },
                                {
                                    label: (
                                        <AppstoreOutlined />
                                    ),
                                    value: 'comparison',
                                }
                                ]}
                                style={{
                                    backgroundColor: '#000',
                                }}
                            />                        
                        </>
                        
                    )
                }
            </div>
            <div className="page-header-login">
                <Upload {...uploadProps}>
                    <Button>Upload</Button>
                </Upload>
                
            </div>            
            <div className="page-header-login">
                <Button onClick={onLogoutClick}>Logout</Button>
            </div>
        </div>
    );
}