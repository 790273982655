import { IndustryClassifier } from "../lib/classifer";
import Papa from "papaparse";

export const SourceLoader = async (source?: string) => {
    const file = process.env.REACT_APP_CSV_SOURCE ?? '';
    const token = process.env.REACT_APP_SAS_TOKEN ?? '';
    
    try {
        if (source || (file && token)) {
            const uri = source ?? (file + token);
            const raw = await fetch(uri);
            const data = Papa.parse(await raw.text(), { header: true }).data;
        
            const result: any[] = [];
        
            for (const row of data as any) {
                const index = result.findIndex((item) => item.name === row['bucket']);
                const item = {
                    id: row['id'],
                    description: row['description'],
                    group: row['source'] && row['source'] !== '' ? row['source'] : 'lfxvp',
                    status: row['status'],
                    reminder: row['reminders'],
                    reason: row['passed reason'],
                    category: row['bucket'],
                    subcategory: row['sub category'],
                    ics: row['ics'],
                    icon: row['icon_url'],
                    name: row['name'],
                    logo: row['icon'],
                    region: row['country'],
                    url: row['website'],
                }
                if (index === -1) {
                    if (row['bucket']) {
                        const category = IndustryClassifier.classify(row['bucket']);
                        result.push({
                            name: row['bucket'],
                            companies: [item],
                            type: category,
                        });
                    }                    
                } else {
                    result[index].companies.push(item);
                }
            }
            const final = result.filter((item) => item.name);
            return final;
        } else {
            throw new Error(`Unable to load datasource at ${file} & ${token}`);
        }
    } catch (ex) {
        console.error(ex);
    }
}